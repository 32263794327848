import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import DatePicker, { registerLocale } from "react-datepicker";
import zhCN from "date-fns/locale/zh-CN";
import { useAlert } from "react-alert";
import { css } from "emotion";
import "./react-datepicker.min.css";
import "./Combox.css";
import "./Form.css";

import format from "date-fns/format";
import addMonths from "date-fns/addMonths";
import subMonths from "date-fns/subMonths";
import addDays from "date-fns/addDays";

import {
  fetchMaxId,
  fetchStandardsPart,
  fetchSampleAdd,
  fetchSampleEdite,
  fetchProjectpart,
  fetchSampleaddress,
  fetchSamplenickname,
  fetchSamplestatus,
  fetchDesignrequire,
  fetchNumberofsample,
  fetchModelnumber,
  fetchTrustdoc,
  fetchLastSample,
  fetchSampleExcel,
} from "../apidata/fetchSampleData";
import { fetchStandardsFilter } from "../apidata/fetchStandard";
import { fetchTrustAllInfo, fetchAddresses } from "../apidata/fetchTrustData";
import {
  fetchProjectNameAll,
  fetchPriceverByProject_id,
  fetchFindProjectNameById,
} from "../apidata/fetchProjectNameData";
import { fetchArgumentPkgsJh } from "../apidata/fetchArgumentPkgData";
import { fetchPkgPriceByVer } from "../apidata/fetchPkgPrice";

import {
  fetchArguments,
  fetchArgumentsFromPkg,
} from "../apidata/fetchArgument";

import { fetchPriceByVer } from "../apidata/fetchPrice";
import { fetchFindPriceVer } from "../apidata/fetchPriceVer";
import { fetchExtracostByArgument } from "../apidata/fetchExtraCost";
import { fetchCustomers } from "../apidata/fetchCustomerData";
import { fetchSampleCodeAll } from "../apidata/fetchSampleCodeData";
import useQueryMakefactory from "../apidata/useQueryMakefactory";

import ArgumentProperty from "../argument/ArgumentProperty";

import {
  payments,
  samplesources,
  sendways,
  testkinds,
  sampledealwiths,
} from "../Enum";

import ExtraInfoList from "./ExtraInfoList";
import ExtraCostList from "../argument/ExtraCostList";
import { fetchModelName } from "../apidata/fetchExtraModelData";
import {checkData} from "./checkData.js"

// 初始值,停用
const initValuesDefault = {
  category: null,
  samplecode: null,
  argumentpkg: null,
  arguments: null,
  accordings: null,
  standards: null,
  trustname: null,
  projectname: null,
  sampleaddress: null,
  projectpart: null,
  samplenickname: null,
  samplestatus: null,
  makefactory: null,
  address: null,

  payment: 0,
  trustdate: new Date(),
  trustperson: null,
  trustpersonphone: null,
  trustpersondate: new Date(),
  lookname: null,
  look_person: null,
  lookperson_cardnumber: null,
  samplingname: null,
  samplingperson: null,
  samplingperson_cardnumber: null,

  modelnumber: null,
  numberofsample: null,
  lotnumber: null,
  batchnumber: null,
  makedate: null,
  factorynumber: null,
  desginrequire: null,
  agetime: null,
  trustdoc: null,
  remark: null,

  samplesource: 0,
  isoffice: 0,
  testkind: 0,

  // extracost:0,
  totalcost: 0,
  reportdate: new Date(),
  sampledealwith: 0,
  sendway: 0,
  receivepersondate: new Date(),
};

let count = 0;
export default function SampleFromJh({
  id = null,
  operate = "add",
  initValues = initValuesDefault,
}) {
  count++;
  const alert = useAlert();
  const {
    register,
    unregister,
    handleSubmit,
    errors,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
  } = useForm({ validateCriteriaMode: "all" });
  registerLocale("zhCN", zhCN); //日期中文化
  const [idInside, setIdInSide] = useState();
  const [operateInside, setOperateInside] = useState("add");
  const [initValuesInside, setInitValueInside] = useState();

  useEffect(() => {
    setIdInSide(id);
    setOperateInside(operate);
    setInitValueInside(initValues);
  }, [id, operate, initValues]);

  const [
    showDialogArgumentsProperty,
    setShowDialogArgumentsProperty,
  ] = useState(false);
  const cancelRef = useRef();
  const openArgumentsProperty = () => setShowDialogArgumentsProperty(true);
  const closeArgumentsProperty = () => setShowDialogArgumentsProperty(false);

  // register control component
  useEffect(() => {
    register({ name: "tag" }, { required: "need tag" });

    register({ name: "pkg_id" });
    register({ name: "isone" });
    register({ name: "onecost" });
    register({ name: "pricever" });
    register({ name: "discount" });
    register({ name: "codediscount" });
    //委托单位
    register({ name: "customer" }, { required: "没有客户,检测会亏本的哦?" });
    // 工程名和id  project这个关键字不能使用,切记
    register(
      { name: "projectname" },
      { required: "工程名称不能为空,没有选到" }
    );
    register({ name: "category" }, { required: "类目绝对不能不知道的?" });
    register({ name: "samplecode" }, { required: "请给我一个唯一的代号?" });
    register(
      { name: "arguments" },
      {
        required: "没有勾选到参数?",
        validate: (value) =>
          !Array.isArray(value) || value.length || "没有勾选到参数?",
      }
    );
    register({ name: "argumentsproperty" });
    register(
      { name: "argumentsprice" },
      { required: "请记得点计算价格,产生总价" }
    );
    register({ name: "extracost" });
    register(
      { name: "accordings" },
      {
        required: "没有勾选到检测依据?",
        validate: (value) =>
          !Array.isArray(value) || value.length || "没有勾选到检测依据?",
      }
    );
    register(
      { name: "standards" },
      {
        required: "没有勾选到判定标准?",
        validate: (value) =>
          !Array.isArray(value) || value.length || "没有勾选到判定依据?",
      }
    );

    register(
      { name: "makefactory" },
      {
        required: "不能为空",
        maxLength: {
          value: 256,
          message: "不能大于256个字",
        },
      }
    );

    // 附加信息
    register({ name: "extrainfo" });

    register({ name: "trustdate" });
    register({ name: "trustpersondate" });
    register({ name: "reportdate" });
    register({ name: "receivepersondate" });
    register({ name: "middlereportdate" });
    setValue("trustdate", format(new Date(), "yyyy-MM-dd", new Date()));
    setValue("trustpersondate", format(new Date(), "yyyy-MM-dd", new Date()));
    setValue("reportdate", format(new Date(), "yyyy-MM-dd", new Date()));
    setValue("receivepersondate", format(new Date(), "yyyy-MM-dd", new Date()));
    setValue("middlereportdate", format(new Date(), "yyyy-MM-dd", new Date()));

    register({ name: "payment" });
    register({ name: "samplesource" });
    register({ name: "testkind" });
    register({ name: "sendway" });
    register({ name: "sampledealwith" });

    return () => unregister;
  }, [register, unregister, setValue]);
  // server 提示信息
  const [msg, setMsg] = useState(null);
  const [priceLoading, setPriceLoading] = useState(false);
  // 找开全参数列表
  const [turnOnArguments, setTurnOnArguments] = useState(false);
  // 按类目打开标准(全部)
  const [turnOnStandards, setTurnOnStandards] = useState(false);
  // 按参数打开标准(部分)
  const [turnOnStandardsPart, setTurnOnStandardsPart] = useState(false);

  // 指示提交
  const [isSubmit, setIsSubmit] = useState(false);
  // 提示加载委托信息
  const [loading, setLoading] = useState(false);

  // 焦点
  const firstInput = useRef();

  // 修改时,首次覆盖
  const [isFirst, setIsFirst] = useState(true);

  // 显示单号的日期年,以server为准
  const currentday = new Date();
  const year = currentday.getFullYear();

  // 大类别
  const [categoryValues, setCategoryValues] = useState(null);

  // 样品代码
  const [sampleCodeItems, setSampleCodeItems] = useState([]); //db
  const [sampleCodeValues, setSampleCodeValues] = useState();
  const [sampleCodeError, setSampleCodeError] = useState(null);

  // 自定义参数包
  const [argumentPkgItems, setArgumentPkgItems] = useState([]); //db
  const [argumentPkgValues, setArgumentPkgValues] = useState(null);
  const [argumentPkgError, setArgumentPkgError] = useState(null);

  // 参数
  const [argumentsItems, setArgumentsItems] = useState([]); //db
  const [argumentsValues, setArgumentsValues] = useState(null);
  const [argumentsError, setArgumentsError] = useState(null);

  // 参数属性  从数据库
  const [argumentPropertyIn, setArgumentPropertyIn] = useState([]); //db
  // 参数属性  填好后的数据
  const [argumentPropertyOut, setArgumentPropertyOut] = useState();

  // 价格 从数据库
  const [priceItems, setPriceItems] = useState(); //db
  // 附加费用  从数据库
  const [extraCostItemsIn, setExtraCostItemsIn] = useState([]); //db
  // 附加费用  填好后的数据
  const [extraCostItemsOut, setExtraCostItemsOut] = useState();
  // 杂费,不明费用
  const otherCost = parseInt(watch("othercost"));
  // 组数,参与计价
  const num1 = parseInt(watch("num1"));

  const [isOne, setIsOne] = useState(false);
  // 整套价格
  const [oneCost, setOneCost] = useState(0);

  // 代码折扣
  const [codediscount, setCodeDiscount] = useState(1);

  // 判定依据
  const [accordingsItems, setAccordingsItems] = useState([]); //db
  const [accordingsValues, setAccordingsValues] = useState(null);
  const [accordingsError, setAccordingsError] = useState(null);

  // 判定标准
  const [standardsItems, setStandardsItems] = useState([]); //db
  const [standardsValues, setStandardsValues] = useState(null);
  const [standardsError, setStandardsError] = useState(null);

  // 委托单位
  const [customerValues, setCustomerValues] = useState();
  const [customerError, setCustomerError] = useState();

  // 工程名称
  const [projectNameItems, setProjectNameItems] = useState([]); //db
  const [projectNameValues, setProjectNameValues] = useState();
  const [projectNameError, setProjectNameError] = useState();

  const [projectPriceVerNew, setProjectPriceVerNew] = useState();
  const [projectPriceVerOld, setProjectPriceVerOld] = useState();
  // 查询地址
  const [addressItems, setAddressItems] = useState();
  const [addressError, SetaddressError] = useState();

  // 查询工程部位
  const [projectpartItems, setProjectpartItems] = useState();
  const [projectpartError, SetProjectpartError] = useState();

  // 查询取样地址
  const [sampleaddressItems, setSampleAddressItems] = useState();
  const [sampleaddressError, SetSampleaddressError] = useState();

  // 样品呢称
  const [samplenicknameItems, setSamplenicknameItems] = useState();
  const [samplenicknameError, SetSamplenicknameError] = useState();

  // 样品状态描述
  const [samplestatusItems, setSamplestatusItems] = useState();
  const [samplestatusError, SetSamplestatusError] = useState();

  // 设计要求
  const [designrequireItems, setDesignrequireItems] = useState();
  const [designrequireError, setDesignrequireError] = useState();

  // 委托文档
  const [trustdocItems, setTrustdocItems] = useState();
  const [trustdocError, setTrustdocError] = useState();

  // 样品数量
  const [numberofsampleItems, setNumberofsampleItems] = useState();
  const [numberofsampleError, setNumberofsampleError] = useState();

  // 规格型号
  const [modelnumberItems, setModelnumberItems] = useState();
  const [modelnumberError, setModelnumbercError] = useState();

  // 制造工厂
  const [makeFactoryIsFetch, setMakeFactoryIsFetch] = useState(false);
  const [makeFactory, setMakefactory] = useState("");
  const makefactories = useQueryMakefactory(makeFactory, makeFactoryIsFetch);

  // 模板名
  const [modelNameItems, setModelNameItems] = useState([]); //db
  const [modelNameValues, setModelNameValues] = useState(null);
  const [modelNameError, setModelNameError] = useState(null);
  const [extraInfoIn, setExtraInfoIn] = useState([]); //初始值不能为null
  const [extraInfoOut, setExtraInfoOut] = useState();

  const [payment, setPayment] = useState(1);
  const [sampleSource, setSampleSource] = useState(0);
  const [sampleDealWith, setSampleDealWith] = useState(0);
  const [testKind, setTestKind] = useState(0);
  const [sendWay, setSendWay] = useState(0);

  const [trustDate, setTrustDate] = useState(new Date());
  const [trustPeronDate, setTtrustPersonDate] = useState(new Date());
  const [reportDate, setReportDate] = useState(new Date());
  const [receivePersonDate, setReceivePersonDate] = useState(new Date());
  const [middleReportDate, setMiddleReportDate] = useState(new Date());

  const samplenickname = watch("samplenickname");

  // 服务端两个maxid
  const [maxId, setMaxId] = useState();

  // 加载样品代码
  useEffect(() => {
    (async () => {
      try {
        const json = await fetchSampleCodeAll();
        setSampleCodeItems(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText);
      }
    })();
  }, []);

  // 找到最大样品号
  useEffect(() => {
    if (!sampleCodeValues) {
      return;
    }
    setCategoryValues(sampleCodeValues.category);
    setValue("category", sampleCodeValues.category);
    (async () => {
      try {
        const json = await fetchMaxId(sampleCodeValues);
        setMaxId(json);
      } catch (error) {
        setSampleCodeError(error.message || error.statusText); //要改成样号取不到
      }
    })();
  }, [sampleCodeValues, setValue]);

  // 动态填充参数包
  useEffect(() => {
    if (!sampleCodeValues || !projectNameValues) {
      return;
    }
    //清空
    setArgumentPkgValues(null);
    setValue("argumentpkg", null);
    clearErrors("argumentpkg");
    (async () => {
      try {
        const json = await fetchArgumentPkgsJh(sampleCodeValues.stdcode);
        setArgumentPkgItems(json);
        // 加果有工程名称,再次过滤(按价格版本)
        if (!Array.isArray(json) || !json.length) {
        } else {
          const temp1 = json.filter(
            (c) =>
              !c.isone || (c.isone && c.pricever === projectNameValues.pricever)
          );
          setArgumentPkgItems(temp1);
        }
      } catch (error) {
        setArgumentPkgError(error.message || error.statusText);
      }
    })();
  }, [sampleCodeValues, projectNameValues, setValue, clearErrors]);

  // 按大类填充参数
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    setArgumentsValues(null);
    setValue("arguments", null);
    (async () => {
      try {
        const json = await fetchArguments(categoryValues.id);
        setArgumentsItems(json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue, turnOnArguments]);

  // 显示所在类的依据和标准
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    // 关掉按参数出标准
    setTurnOnStandardsPart(false);

    setAccordingsValues(null);
    setValue("accordings", null);
    setStandardsValues(null);
    setValue("sandards", null);

    (async () => {
      try {
        const json = await fetchStandardsFilter(categoryValues);
        setAccordingsItems(json);
        setStandardsItems(json);
      } catch (error) {
        setAccordingsError(error.message || error.statusText);
        setStandardsError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue, turnOnStandards, setTurnOnStandardsPart]);

  // 按自定义包,填充参数 整套价格处理
  useEffect(() => {
    if (!argumentPkgValues) {
      return;
    }
    setArgumentsValues(null);
    setValue("arguments", null);

    (async () => {
      try {
        const json = await fetchArgumentsFromPkg(argumentPkgValues.id);
        setArgumentsItems(json);
        setValue("pkg_id", argumentPkgValues.id);
        setIsFirst(false);
        if (argumentPkgValues.checkall) {
          setArgumentsValues(json); //直接全选
          setValue("arguments", json);
        }
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
  }, [argumentPkgValues, setValue]);

  // 按所选参数加载依据和标准(部分)
  useEffect(() => {
    if (
      !argumentsValues ||
      !turnOnStandardsPart ||
      argumentsValues.length === 0
    ) {
      return;
    }
    setAccordingsValues(null);
    setValue("accordings", null);
    setStandardsValues(null);
    setValue("sandards", null);

    const idList = argumentsValues.map((item) => item.id);

    (async () => {
      try {
        const json = await fetchStandardsPart(idList);
        setAccordingsItems(json);
        setStandardsItems(json);
      } catch (error) {
        setAccordingsError(error.message || error.statusText);
        setStandardsError(error.message || error.statusText);
      }
    })();
  }, [argumentsValues, setValue, turnOnStandardsPart]);

  // 选择委托单位后为:工程名称,邮寄地址提供数据
  useEffect(() => {
    if (customerValues) {
      (async () => {
        try {
          const json = await fetchProjectNameAll(customerValues.id);
          setProjectNameItems(json);
        } catch (error) {
          setProjectNameError(error.message || error.statusText);
        }
      })();

      (async () => {
        try {
          const json = await fetchAddresses(customerValues.id);
          setAddressItems(json);
        } catch (error) {
          SetaddressError(error.message || error.statusText);
        }
      })();
    }
  }, [customerValues]);

  // 类目为 设计要求 提供数据
  useEffect(() => {
    if (categoryValues) {
      (async () => {
        try {
          const json = await fetchDesignrequire(categoryValues.id);
          setDesignrequireItems(json);
        } catch (error) {
          setDesignrequireError(error.message || error.statusText);
        }
      })();
    }
  }, [categoryValues]);

  // 类目 为委托文档提供数据
  useEffect(() => {
    if (categoryValues) {
      (async () => {
        try {
          const json = await fetchTrustdoc(categoryValues.id);
          setTrustdocItems(json);
        } catch (error) {
          setTrustdocError(error.message || error.statusText);
        }
      })();
    }
  }, [categoryValues]);

  // 工程名称id 查找价格版本
  useEffect(() => {
    if (projectNameValues) {
      (async () => {
        try {
          const json = await fetchFindPriceVer(projectNameValues.pricever);
          setProjectPriceVerOld(json);
          const json2 = await fetchPriceverByProject_id(projectNameValues.id);
          setProjectPriceVerNew(json2);
        } catch (error) {
          alert.error(error.message || error.statusText);
        }
      })();
    }
  }, [projectNameValues, alert]);

  // 工程名称 为工程部位提供数据
  useEffect(() => {
    if (projectNameValues) {
      (async () => {
        try {
          const json = await fetchProjectpart(projectNameValues.id);
          setProjectpartItems(json);
        } catch (error) {
          SetProjectpartError(error.message || error.statusText);
        }
      })();
    }
  }, [projectNameValues]);

  // 工程名称 为工程部位提供数据
  useEffect(() => {
    if (projectNameValues) {
      (async () => {
        try {
          const json = await fetchSampleaddress(projectNameValues.id);
          setSampleAddressItems(json);
        } catch (error) {
          SetSampleaddressError(error.message || error.statusText);
        }
      })();
    }
  }, [projectNameValues]);

  // 类目为 样品呢称 提供数据
  useEffect(() => {
    if (categoryValues) {
      (async () => {
        try {
          const json = await fetchSamplenickname(categoryValues.id);
          setSamplenicknameItems(json);
        } catch (error) {
          SetSamplenicknameError(error.message || error.statusText);
        }
      })();
    }
  }, [categoryValues]);

  // 样品呢称为 样品描述 提供数据
  useEffect(() => {
    if (
      categoryValues &&
      samplenicknameItems &&
      samplenicknameItems.indexOf(samplenickname) !== -1
    ) {
      (async () => {
        try {
          const json = await fetchSamplestatus(samplenickname);
          setSamplestatusItems(json);
        } catch (error) {
          SetSamplestatusError(error.message || error.statusText);
        }
      })();
    }
  }, [samplenickname, samplenicknameItems, categoryValues]);

  // 类目为 样品数量 提供数据
  useEffect(() => {
    if (categoryValues) {
      (async () => {
        try {
          const json = await fetchNumberofsample(categoryValues.id);
          setNumberofsampleItems(json);
        } catch (error) {
          setNumberofsampleError(error.message || error.statusText);
        }
      })();
    }
  }, [categoryValues]);

  // 类目为规格型号提供数据
  useEffect(() => {
    if (categoryValues) {
      (async () => {
        try {
          const json = await fetchModelnumber(categoryValues.id);
          setModelnumberItems(json);
        } catch (error) {
          setModelnumbercError(error.message || error.statusText);
        }
      })();
    }
  }, [categoryValues]);

  // 点击套餐价格
  const getOnePrice = () => {
    if (
      !projectNameValues ||
      !argumentsValues ||
      !sampleCodeValues ||
      !argumentPkgValues
    ) {
      alert.error("工程名称或样品代码或参数或参数包没有选到");
      return;
    }
    setPriceLoading(true);
    (async () => {
      try {
        const json1 = await fetchPkgPriceByVer(
          argumentPkgValues.id,
          projectNameValues.pricever
        );
        setIsOne(true);
        setValue("isone", true);
        setOneCost(json1.price);
        setValue("onecost", json1.price);
        const argumentIdList = argumentsValues.map((c) => c.id);
        const data = {
          ver: projectNameValues.pricever,
          arguments: argumentIdList,
        };
        const json = await fetchPriceByVer(data);
        setPriceItems(json);
        setValue("argumentsprice", json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
    setPriceLoading(false);
    clearErrors("argumentsprice");
  };
  // 点击查询散装参数价格
  const getPrice = () => {
    if (!projectNameValues || !argumentsValues || !sampleCodeValues) {
      alert.error("工程名称或样品代码或参数没有选到");
      return;
    }
    for(let i=0;i<argumentsValues.length;i++){
      const item = checkData.find(c=>c.samplecode===sampleCodeValues.stdcode && c.stdcode===argumentsValues[i].stdcode);
      if(item!==undefined){
        alert.show(item?.tips,{timeout: 5000});
      }
    }
    
    setIsOne(false);
    setValue("isone", false);
    setOneCost(0);
    setValue("onecost", 0);
    setPriceLoading(true);
    (async () => {
      try {
        const argumentIdList = argumentsValues.map((c) => c.id);
        const data = {
          ver: projectNameValues.pricever,
          arguments: argumentIdList,
        };
        const json = await fetchPriceByVer(data);
        setPriceItems(json);
        setValue("argumentsprice", json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
    setPriceLoading(false);
    clearErrors("argumentsprice");
  };

  // 计算总价
  useEffect(() => {
    //  价格版本(基准价格),样品代码(代码折扣),工程名称(折扣).共同决定打价格
    if (!projectNameValues || !sampleCodeValues || !priceItems) {
      return;
    }

    let priceItemSum = 0;
    if (isOne) {
      priceItemSum = oneCost;
    } else {
      priceItemSum = priceItems
        ? priceItems.map((c) => parseFloat(c.price)).reduce((x, y) => x + y, 0)
        : 0;
    }

    const extraCostSum = extraCostItemsOut
      ? extraCostItemsOut
          .map((item) => parseFloat(item.price) * parseInt(item.qty))
          .reduce((x, y) => x + y, 0)
      : 0;
    const discount = projectNameValues.discount;

    let tempA = 1; // 代码折扣
    const tempC = projectNameValues.codediscount;

    if (!Array.isArray(tempC) || !tempC) {
      tempA = 1;
    } else {
      const tempB = tempC.find((c) => c.stdcode === sampleCodeValues.stdcode); //找代码折扣
      tempA = tempB === undefined ? 1 : parseFloat(tempB.discount); //代码折扣
    }

    setCodeDiscount(tempA); // 代码折扣到界面变量
    setValue("codediscount", tempA); // save to db

    const temp1 = priceItemSum * num1 + extraCostSum; //算折扣的费用
    const temp2 = isNaN(temp1) ? 0 : temp1;
    const total = temp2 * tempA * discount + otherCost;
    setValue("totalcost", Number.parseFloat(total).toFixed(2)); //前2部分价格 * 工程折扣 * 代码折扣
  }, [
    isOne,
    oneCost,
    priceItems,
    extraCostItemsOut,
    otherCost,
    num1,
    setValue,
    projectNameValues,
    sampleCodeValues,
  ]);

  // 动态填充模板名
  useEffect(() => {
    if (!categoryValues) {
      return;
    }
    //清空
    setModelNameItems(null);
    setModelNameValues(null);

    (async () => {
      try {
        const json = await fetchModelName(categoryValues["id"]);
        setModelNameItems(json);
      } catch (error) {
        setModelNameError(error.message || error.statusText);
      }
    })();
  }, [categoryValues, setValue]);

  // 选到模板名后,载入模板
  useEffect(() => {
    if (modelNameValues && modelNameValues.model) {
      setExtraInfoIn(modelNameValues.model);
    }
  }, [modelNameValues]);

  // pull server data  异步 委托单位数据
  const promiseOptions = async (inputValue) => {
    if (inputValue === null) {
      return Promise.reject("error");
    }
    if (inputValue.length < 1) {
      return Promise.reject("error");
    }

    try {
      const json = await fetchCustomers(inputValue);
      setCustomerValues(null);
      return json;
    } catch (error) {
      setCustomerError(error.message || error.statusText);
      return Promise.reject(error);
    }
  };

  // 查询委托单位名称
  const handleCustomerChange = (event) => {
    if (event) {
      setValue("customer", event); // form 的值
      setCustomerValues(event);
      setValue("projectname", null);
      setProjectNameValues(null);
    } else {
      setError("customer", "notMatch", "没有选择到委托单位?");
    }
    return clearErrors("customer");
  };

  // 选择工程名称, 注意错了好久
  const handleProjectNameChange = (selectedOption) => {
    setValue("projectname", selectedOption); // form 的值
    setValue("pricever", selectedOption.pricever);
    setValue("discount", selectedOption.discount);
    setProjectNameValues(selectedOption); // 控件的值
    // 修改时改动了工程名称,标识
    if (
      operate !== "add" &&
      initValues.trust.project_id !== selectedOption.id
    ) {
      setIsFirst(false);
    }

    if (!selectedOption) {
      setError("projectname", "notMatch", "没有选择到工程名称!");
    }
    return clearErrors("projectname");
  };

  // 选择样品代码
  const handleChangeSampleCode = (selectedOption) => {
    if (selectedOption) {
      setValue("samplecode", selectedOption); // form 的值
      setSampleCodeValues(selectedOption); // 控件的值
      setIsFirst(false);
      return clearErrors("samplecode");
    } else {
      setError("samplecode", "notMatch", "没有选择到样品编号!");
    }
  };

  // 选择参数包(虚包)
  const handleChangeArgumentPkg = (selectedOption) => {
    if (selectedOption) {
      setValue("argumentpkg", selectedOption); // form 的值
      setArgumentPkgValues(selectedOption); // 控件的值
      return clearErrors("argumentpkg");
    } else {
      setError("argumentpkg", "notMatch", "没有选择到自定义参数包?");
    }
  };

  // 选择参数
  const handleChangeArguments = (selectedOption) => {
    setValue("arguments", selectedOption); // form 的值
    setArgumentsValues(selectedOption); // 控件的值
    return clearErrors("arguments");
  };

  // 选择依据
  const handleChangeAccordings = (selectedOption) => {
    if (selectedOption) {
      setValue("accordings", selectedOption); // form 的值
      setAccordingsValues(selectedOption); // 控件的值
      return clearErrors("accordings");
    } else {
      setError("accordings", "notMatch", "没有选择到样品编号!");
    }
  };

  // 选择判定标准
  const handleChangeStandards = (selectedOption) => {
    if (selectedOption) {
      setValue("standards", selectedOption); // form 的值
      setStandardsValues(selectedOption); // 控件的值
      return clearErrors("standards");
    } else {
      setError("standards", "notMatch", "没有选择到样品编号!");
    }
  };

  // 附加费用
  const handleExtraCostChange = (data) => {
    setExtraCostItemsOut(data);
    setValue("extracost", data);
  };

  const handlePaymentChange = (event) => {
    setValue("payment", event.target.value);
    setPayment(event.target.value);
  };

  const handleSampleSourceChange = (event) => {
    setValue("samplesource", event.target.value);
    setSampleSource(event.target.value);
  };

  const handleSampleDealWithChange = (event) => {
    setValue("sampledealwith", event.target.value);
    setSampleDealWith(event.target.value);
  };

  const handleTestKindChange = (event) => {
    setValue("testkind", event.target.value);
    setTestKind(event.target.value);
  };

  const handleSendWayChange = (event) => {
    setValue("sendway", event.target.value);
    setSendWay(event.target.value);
  };

  // 查询制造工厂
  const handleMakefactoryChange = (event) => {
    if (event.target.value !== " ") {
      setMakefactory(event.target.value);
      setValue("makefactory", event.target.value);
      setMakeFactoryIsFetch(true);
      return clearErrors("makefactory");
    }
  };

  // 模板选择
  const handleChangeModel = (selectedOption) => {
    setModelNameValues(selectedOption);
  };

  // 接受从子控件发上来的,附加信息
  const handleExtraInfoChange = (data) => {
    setExtraInfoOut(data);
    setValue("extrainfo", extraInfoOut); //给form
  };

  // 报告日期
  const handleReportDateChange = (date) => {
    if (!date) {
      return;
    }
    setValue("reportdate", format(date, "yyyy-MM-dd", new Date()));
    setReportDate(date);
  };
  // 委托日期
  const handleTrustDateChange = (date) => {
    if (!date) {
      return;
    }
    setValue("trustdate", format(date, "yyyy-MM-dd", new Date()));
    setTrustDate(date);
  };
  // 委托人日期
  const handleTrustPersonDateChange = (date) => {
    if (!date) {
      return;
    }
    setValue("trustpersondate", format(date, "yyyy-MM-dd", new Date()));
    setTtrustPersonDate(date);
  };

  // 收样人日期
  const handleReceivePersonDateChange = (date) => {
    if (!date) {
      return;
    }
    setValue("receivepersondate", format(date, "yyyy-MM-dd", new Date()));
    setReceivePersonDate(date);
  };
  // 报告日期
  const handleMiddleReportDateChange = (date) => {
    if (!date) {
      return;
    }
    setValue("middlereportdate", format(date, "yyyy-MM-dd", new Date()));
    setMiddleReportDate(date);
  };

  // 查询助手,查询(委托单位+工程名称下的信息)
  const fetchMyorder = async (projectNameValues) => {
    if (!projectNameValues) {
      alert("请先选择工程名称");
      return;
    }
    setLoading(true);
    try {
      const json = await fetchTrustAllInfo(projectNameValues.id);
      fillpart(json);
    } catch (error) {
      setMsg(error.message || error.statusText);
    }
    setLoading(false);
  };
  // 填充委托信息
  const fillpart = useCallback(
    (trust) => {
      setValue("trustperson", trust.trustperson);
      clearErrors("trustperson");
      setValue("trustpersonphone", trust.trustpersonphone);
      clearErrors("trustpersonphone");
      setValue("sampleperson", trust.sampleperson);
      clearErrors("sampleperson");
      setValue("sampleperson_cardnumber", trust.sampleperson_cardnumber);
      clearErrors("sampleperson_cardnumber");
      setValue("lookname", trust.lookname);
      clearErrors("lookname");
      setValue("lookperson", trust.lookperson);
      clearErrors("lookperson");
      setValue("lookperson_cardnumber", trust.lookperson_cardnumber);
      clearErrors("lookperson_cardnumber");
      setValue("samplingname", trust.samplingname);
      clearErrors("samplingname");
      setValue("samplingperson", trust.samplingperson);
      clearErrors("samplingperson");
      setValue("samplingperson_cardnumber", trust.samplingperson_cardnumber);
      clearErrors("samplingperson_cardnumber");

      setValue("address", trust.address);
      clearErrors("address");

      setPayment(trust.payment);
      setSampleSource(trust.samplesource);
      setTestKind(trust.testkind);
      setSampleDealWith(trust.sampledealwith);
      setSendWay(trust.sendway);
      setValue("payment", trust.payment);
      setValue("samplesource", trust.samplesource);
      setValue("testkind", trust.testkind);
      setValue("reportqty", trust.reportqty);
      setValue("sampledealwith", trust.sampledealwith);
      setValue("sendway", trust.sendway);
    },
    [setValue, clearErrors]
  );

  // 清空
  const clear = (e) => {
    //e.target.reset();
    // 受控清空
    setCustomerValues(null);
    setProjectNameValues(null);
    setArgumentPkgValues(null);
    setArgumentsValues(null);
    setPriceItems(null);
    setAccordingsValues(null);
    setStandardsValues(null);
    setExtraCostItemsIn(null);
    setExtraInfoIn(null);

    // inputTrustName.current.value=""; 此句与setValue 或 reset冲突
    // 下边没有作用,combox
    setMakefactory("");
    // 修改时不清空
    if (operateInside === "edite") {
      reset({
        tag: initValuesInside.trust.tag,
        samplecode: sampleCodeValues,
        category: sampleCodeValues.category,
      });
    } else if (operateInside !== "add") {
      setCategoryValues(null);
      setSampleCodeValues(null);
      setValue("samplecode", null);
      setValue("category", null);
      reset({ tag: 1 });
    } else {
      setCategoryValues(null);
      setSampleCodeValues(null);
      setValue("samplecode", null);
      setValue("category", null);
      reset({ tag: 3 });
    }
  };

  //  copy or edite
  useEffect(() => {
    if (
      operateInside !== "add" &&
      isFirst &&
      sampleCodeItems &&
      sampleCodeItems.length > 0 &&
      initValuesInside.samplecode
    ) {
      const temp = sampleCodeItems.find(
        (c) => c.stdcode === initValuesInside.samplecode
      );
      setSampleCodeValues(temp);
      setValue("samplecode", temp);
      setCategoryValues(temp.category);
      setValue("category", temp.category);
    }
  }, [
    operateInside,
    isFirst,
    initValuesInside,
    sampleCodeItems,
    setSampleCodeValues,
    setValue,
  ]);
  //  copy or edite
  useEffect(() => {
    if (
      operateInside !== "add" &&
      isFirst &&
      categoryValues &&
      argumentsItems &&
      initValuesInside.arguments
    ) {
      setArgumentsValues(initValuesInside.arguments);
      setValue("arguments", initValuesInside.arguments);
      setValue("totalcost", initValuesInside.totalcost); // 保证原总价还原.
    }
  }, [
    operateInside,
    isFirst,
    initValuesInside,
    categoryValues,
    argumentsItems,
    setArgumentsValues,
    setValue,
  ]);
  //  copy or edite
  useEffect(() => {
    if (
      operateInside !== "add" &&
      isFirst &&
      categoryValues &&
      accordingsItems &&
      initValuesInside.accordings
    ) {
      setAccordingsValues(initValuesInside.accordings);
      setValue("accordings", initValuesInside.accordings);
    }
  }, [
    operateInside,
    isFirst,
    initValuesInside,
    categoryValues,
    accordingsItems,
    setAccordingsValues,
    setValue,
  ]);
  //  copy or edite
  useEffect(() => {
    if (
      operateInside !== "add" &&
      isFirst &&
      categoryValues &&
      standardsItems &&
      initValuesInside.standards
    ) {
      setStandardsValues(initValuesInside.standards);
      setValue("standards", initValuesInside.standards);
    }
  }, [
    operateInside,
    isFirst,
    initValuesInside,
    categoryValues,
    standardsItems,
    setStandardsValues,
    setValue,
  ]);

  //  copy or edite
  useEffect(() => {
    if (operateInside !== "add" && isFirst) {
      setMaxId({
        trustnumber: initValuesInside.trust.trustnumber,
        samplenumber: initValuesInside.samplenumber,
      });
    }
  }, [initValuesInside, operateInside, isFirst]);

  // 修改,复制时填充,初始化界面
  useEffect(() => {
    if (operateInside !== "add") {
      // 以后可以与上边共用
      setValue("trustperson", initValuesInside.trust.trustperson);
      setValue("trustpersonphone", initValuesInside.trust.trustpersonphone);
      setValue("sampleperson", initValuesInside.trust.sampleperson);
      setValue(
        "sampleperson_cardnumber",
        initValuesInside.trust.sampleperson_cardnumber
      );
      setValue("lookname", initValuesInside.trust.lookname);
      setValue("lookperson", initValuesInside.trust.lookperson);
      setValue(
        "lookperson_cardnumber",
        initValuesInside.trust.lookperson_cardnumber
      );
      setValue("samplingname", initValuesInside.trust.samplingname);
      setValue("samplingperson", initValuesInside.trust.samplingperson);
      setValue(
        "samplingperson_cardnumber",
        initValuesInside.trust.samplingperson_cardnumber
      );

      const temp1 = {
        id: initValuesInside.trust.customer_id,
        pinyin: "pinyin",
        name: initValuesInside.trust.trustname,
      };
      setCustomerValues(temp1);
      setValue("customer", temp1);

      const temp2 = {
        id: initValuesInside.trust.project_id,
        name: initValuesInside.trust.projectname,
        pricever: initValuesInside.pricever,
        discount: initValuesInside.discount,
        codediscount: [
          {
            id: 1,
            stdcode: initValuesInside.samplecode,
            discount: initValuesInside.codediscount,
          },
        ],
      };
      setProjectNameValues(temp2);
      setValue("projectname", temp2);

      setArgumentPropertyIn(initValuesInside.argumentsproperty);
      setValue("argumentsproperty", initValuesInside.argumentsproperty);
      setPriceItems(initValuesInside.argumentsprice);
      setValue("argumentsprice", initValuesInside.argumentsprice);
      setExtraCostItemsIn(initValuesInside.extracost);
      setExtraCostItemsOut(initValuesInside.extracost);
      setValue("extracost", initValuesInside.extracost);
      setIsOne(initValuesInside.isone);
      setValue("pkg_id", initValuesInside.pkg_id);
      setValue("isone", initValuesInside.isone);
      setOneCost(initValuesInside.onecost);
      setValue("onecost", initValuesInside.onecost); //错误多次

      setMakefactory(initValuesInside.makefactory);
      setValue("customer", {
        id: initValuesInside.trust.customer_id,
        pinyin: "pinyin",
        name: initValuesInside.trust.trustname,
      });

      setValue("projectpart", initValuesInside.projectpart);
      setValue("sampleaddress", initValuesInside.sampleaddress);
      setValue("samplenickname", initValuesInside.samplenickname);
      setValue("samplestatus", initValuesInside.samplestatus);
      setValue("makefactory", initValuesInside.makefactory);
      setValue("address", initValuesInside.trust.address);
      //inputTrustName.current.value=initValues.trust.trustname;

      setPayment(initValuesInside.trust.payment); // 要记得json格式
      setSampleSource(initValuesInside.trust.samplesource);
      setTestKind(initValuesInside.trust.testkind);
      setSampleDealWith(initValuesInside.trust.sampledealwith);
      setSendWay(initValuesInside.trust.sendway);
      setValue("payment", initValuesInside.trust.payment);
      setValue("samplesource", initValuesInside.trust.samplesource);
      setValue("testkind", initValuesInside.trust.testkind);
      setValue("reportqty", initValuesInside.trust.reportqty);
      setValue("sampledealwith", initValuesInside.trust.sampledealwith);
      setValue("sendway", initValuesInside.trust.sendway);

      setReportDate(Date.parse(initValuesInside.reportdate));
      setTrustDate(Date.parse(initValuesInside.trustdate));
      setTtrustPersonDate(Date.parse(initValuesInside.trustpersondate));
      setReceivePersonDate(Date.parse(initValuesInside.receivepersondate));
      setMiddleReportDate(Date.parse(initValuesInside.middlereportdate));

      setValue("reportdate", initValuesInside.reportdate);
      setValue("trustdate", initValuesInside.trustdate);
      setValue("trustpersondate", initValuesInside.trustpersondate);
      setValue("receivepersondate", initValuesInside.receivepersondate);
      setValue("middlereportdate", initValuesInside.middlereportdate);

      setValue("othername", initValuesInside.othername);
      setValue("othercost", initValuesInside.othercost);
      setValue("totalcost", initValuesInside.totalcost);
      setValue("numberofsample", initValuesInside.numberofsample);
      setValue("num1", initValuesInside.num1);
      setValue("num2", initValuesInside.num2);
      setValue("modelnumber", initValuesInside.modelnumber);
      setValue("designrequire", initValuesInside.designrequire);
      setValue("trustdoc", initValuesInside.trustdoc);
      setValue("remark", initValuesInside.remark);

      // 附加信息
      setExtraInfoIn(
        initValuesInside.extrainfo === null ? [] : initValuesInside.extrainfo
      );
      setValue("extrainfo", initValuesInside.extrainfo);

      setValue("ismiddlereport", initValuesInside.ismiddlereport);

      if (operateInside === "copy") {
        setValue("tag", 3);
      } else {
        setValue("tag", initValuesInside.trust.tag);
      }
    } else {
      //  新增 初始值
      setValue("tag", 1);
      setValue("pkg_id", 0);
      setValue("isone", false);
      setValue("oncost", 0);
      setValue("extracost", null);

      setValue("payment", 1);
      setValue("samplesource", 0);
      setValue("testkind", 0);
      setValue("sampledealwith", 0);
      setValue("sendway", 0);
    }
  }, [setValue, initValuesInside, operateInside, alert]);
  // 参数包回显,修改或复制时
  useEffect(() => {
    if (isFirst && operateInside !== "add" && initValuesInside.isone) {
      setArgumentPkgValues(
        argumentPkgItems.find((c) => c.id === initValuesInside.pkg_id)
      );
    }
  }, [argumentPkgItems, initValuesInside, isFirst, operateInside]);
  // 传输工程的价格(价格版本+工程折扣+代码折扣)
  const transmitProjectPrice = async () => {
    if (projectNameValues) {
      try {
        const json = await fetchFindProjectNameById(projectNameValues.id);
        setProjectNameValues(json);
        setValue("projectname", json); // form 的值
        setValue("pricever", json.pricever);
        setValue("discount", json.discount);
      } catch (error) {
        setProjectNameError(error.message || error.statusText);
      }
    }
  };

  // 找参数包
  const findPkg = () => {
    if (!sampleCodeValues || !projectNameValues) {
      alert.error("没有工程名称或样品代码?");
      return;
    }
    //清空
    setArgumentPkgValues(null);
    setArgumentPkgItems(null);
    clearErrors("argumentpkg");
    (async () => {
      try {
        const json = await fetchArgumentPkgsJh(sampleCodeValues.stdcode);
        setArgumentPkgItems(json);
        // 加果有工程名称,再次过滤(按价格版本)
        if (!Array.isArray(json) || !json.length) {
        } else {
          const temp1 = json.filter(
            (c) =>
              !c.isone || (c.isone && c.pricever === projectNameValues.pricever)
          );
          setArgumentPkgItems(temp1);
        }
      } catch (error) {
        setArgumentPkgError(error.message || error.statusText);
      }
    })();
  };
  // 找参数
  const findArguments = () => {
    if (!categoryValues) {
      return;
    }
    (async () => {
      try {
        const json = await fetchArguments(categoryValues.id);
        setArgumentsItems(json);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
  };
  // 找标准
  const findStandard = () => {
    if (!categoryValues) {
      return;
    }
    // 关掉按参数出标准
    setTurnOnStandardsPart(false);

    (async () => {
      try {
        const json = await fetchStandardsFilter(categoryValues);
        setAccordingsItems(json);
        setStandardsItems(json);
      } catch (error) {
        setAccordingsError(error.message || error.statusText);
        setStandardsError(error.message || error.statusText);
      }
    })();
  };
  // 找附加费用
  const findExtraCost = () => {
    if (!argumentsValues || !sampleCodeValues) {
      alert("样品代码或参数没有选到");
      return;
    }
    if(argumentsValues.length===0){
      return;
    }
    (async () => {
      try {
        const argumentIdList = argumentsValues.map((c) => c.id);
        const extraCostJson = await fetchExtracostByArgument({
          arguments: argumentIdList,
        });

        // 去重
        const uniqueExtraCostJson = extraCostJson.filter(
          (item, index, self) =>
            index ===
            self.findIndex(
              (c) =>
                c.name === item.name &&
                c.price === item.price &&
                c.unit === item.unit
            )
        );

        setExtraCostItemsIn(uniqueExtraCostJson);
        setExtraCostItemsOut(uniqueExtraCostJson);
        setValue("extracost", uniqueExtraCostJson);
      } catch (error) {
        setArgumentsError(error.message || error.statusText);
      }
    })();
  };
  // 找附加信息模板
  const findExtraInfo = () => {
    if (!categoryValues) {
      alert("还没有选样品代码");
      return;
    }
    //清空
    setModelNameItems(null);
    setModelNameValues(null);

    (async () => {
      try {
        const json = await fetchModelName(categoryValues["id"]);
        setModelNameItems(json);
      } catch (error) {
        setModelNameError(error.message || error.statusText);
      }
    })();
  };

  const handleArgumentPropertyChange = (data) => {
    setArgumentPropertyOut(data);
    setValue("argumentsproperty", argumentPropertyOut);
  };

  const copyLastSample = async () => {
    try {
      const json = await fetchLastSample();
      setInitValueInside(json);
      setOperateInside("copy");
      setMsg("复制成功");

      setTimeout(() => {
        setMsg(null);
      }, 5000);
    } catch (error) {
      setMsg(error.message);
    }
  };

  const [isDownload, setIsDownload] = useState(false);

  const download = async (item) => {
    try {
      const res = await fetchSampleExcel(item.id);
      const blob = await res.blob();
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      link.href = url;
      link.download = `JHWT-${
        item.trust.year
      }-${item.trust.trustnumber.toString().padStart(4, "0")}`;
      link.click();
      window.URL.revokeObjectURL(url);
      setMsg("导出成功");
      setTimeout(() => {
        setMsg(null);
      }, 5000);
    } catch (error) {
      setMsg("导出失败" + error.message);
    }
  };
  // 复制时校验工程价格版本与折扣
  function checkDiscount() {
    if (operateInside === "copy") {
      const a = projectNameValues.pricever === projectPriceVerNew.pricever;
      const b = projectNameValues.discount === projectPriceVerNew.discount;
      if (a && b) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  // 提交server
  const onSubmit = async (data) => {
    if (!checkDiscount()) {
      alert.error("工程价格版本或折扣有更新!先同步工程,再点计价,才能来提交?");
      return;
    }
    // triggerValidation();
    setIsSubmit(true);
    if (operateInside === "edite") {
      try {
        const json = await fetchSampleEdite(idInside, data);
        alert.success(json.msg);
        setMsg(json.msg);
        if (isDownload) {
          download(json.data);
        }
        setTimeout(() => {
          // document.location.reload();
          // firstInput.current.focus();
          setMsg(null);
          //window.scrollTo(0, 0);
        }, 5000);
      } catch (error) {
        alert.error(error.message);
        setMsg(error.message || error.statusText);
      }
    } else {
      try {
        const json = await fetchSampleAdd(data);
        alert.success(json.msg);
        setMsg(json.msg);
        if (isDownload) {
          download(json.data);
        }
        setInitValueInside(json.data);
        setIdInSide(json.data.id);
        setOperateInside("edite");
        setTimeout(() => {
          // document.location.reload();
          // firstInput.current.focus();
          setMsg(null);
        }, 5000);
      } catch (error) {
        alert.error(error.message);
        setMsg(error.message || error.statusText);
      }
    }
    setIsSubmit(false);
  };

  // 交航完整委托编号
  const trustNo = () => {
    if (operate !== "edite" && sampleCodeValues && maxId) {
      const temp = `${year}-${maxId.trustnumber.toString().padStart(4, "0")}`;
      if (sampleCodeValues.category.certificate === 0) {
        // 公路
        return `JHWT-${temp}`;
      } else {
        return `JHSWT-${temp}`;
      }
    }
    //修改时
    else if (operate === "edite" && sampleCodeValues && maxId) {
      const temp = `${initValues.trust.year}-${initValues.trust.trustnumber
        .toString()
        .padStart(4, "0")}`;
      if (sampleCodeValues.category.certificate === 0) {
        // 公路
        return `JHWT-${temp}`;
      } else {
        return `JHSWT-${temp}`;
      }
    } else {
      return null;
    }
  };

  // 交航完整样品编号
  const sampleNo = () => {
    if (operate !== "edite" && sampleCodeValues && maxId) {
      return `JHYP-${year}-${
        sampleCodeValues.stdcode
      }-${maxId.samplenumber.toString().padStart(4, "0")}`;
    }
    //修改时
    else if (operate === "edite" && sampleCodeValues && maxId) {
      return `JHYP-${initValues.year}-${
        sampleCodeValues.stdcode
      }-${initValues.samplenumber.toString().padStart(4, "0")}`;
    } else {
      return null;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={css`
            display: grid;
            grid-template-columns: 1fr 0.2fr;
            grid-auto-rows: max-content;
            // grid-template-rows: 1fr 1fr 1fr 1fr; //一样高
            grid-gap: 5px;
            box-sizing: border-box;
          `}
        >
          <div
            className={css`
              grid-column: 1;
              grid-row: 1;
              border: 1px solid black;
              padding: 5px;
            `}
          >
            <div className="form-group row">
              <label className="label">委托单位:</label>
              <AsyncSelect
                name="trustname"
                className={css`
                  width: 80%;
                  height: atuo;
                  display: inline-block;
                `}
                cacheOptions
                defaultOptions
                loadOptions={promiseOptions}
                getOptionValue={(option) => option["id"]}
                onChange={handleCustomerChange}
                getOptionLabel={(option) => `${option.pinyin} | ${option.name}`}
                value={customerValues}
                placeholder="请输入委托单位名称"
                ref={firstInput}
              />
            </div>

            <div className="form-group row">
              {customerError}
              <div className="error">{errors.customer?.message}</div>
            </div>

            <div className="form-group row">
              <i className="glyphicon glyphicon-cloud"></i>
              <label className="label">工程名称:</label>
              <Select
                className="select-big"
                name="project"
                placeholder="工程名称"
                value={projectNameValues}
                options={projectNameItems}
                onChange={handleProjectNameChange}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => option.name}
              />
              <div style={{ display: "inlneblock", color: "red" }}>
                {projectNameError}
                {errors.projectname && errors.projectname.message}
              </div>
            </div>

            <div className="form-group row">
              <label className="label">样品代码:</label>
              <Select
                isDisabled={operateInside === "edite"}
                className="select-small"
                name="samplecode"
                placeholder="样品代码"
                value={sampleCodeValues}
                options={sampleCodeItems}
                onChange={handleChangeSampleCode}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) =>
                  `${option.stdcode} | ${
                    option.category.certificate === 0 ? "公路" : "水运"
                  } | ${option.name}`
                }
              />
              <div style={{ display: "inlneblock", color: "red" }}>
                {sampleCodeError}
                {errors.samplecode && errors.samplecode.message}
              </div>
            </div>

            <div className="form-group row">
              <label className="label">自定义参数包:</label>
              <Select
                className="select-small"
                name="argumnentpkg"
                isClearable
                placeholder="参数包(可以不选的哦,不小心选了的话,等下可以点右边取消)"
                value={argumentPkgValues}
                options={argumentPkgItems}
                onChange={handleChangeArgumentPkg}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => `${option.name}`}
              />
              <span
                onClick={() => {
                  setTurnOnArguments(!turnOnArguments);
                  setArgumentPkgValues(null);
                  setIsFirst(false);
                  setIsOne(false);
                  setValue("isone", false);
                  setValue("pkg_id", 0);
                }}
              >
                我不想选了
              </span>
              {argumentPkgError}
              {errors.argumentPkg && errors.argumentPkg.message}
            </div>

            <div className="form-group row">
              <label className="label">参数选择:</label>
              <Select
                className="select-big"
                name="arguments"
                isMulti
                closeMenuOnSelect={false}
                isClearable
                placeholder="参数(可多选)"
                value={argumentsValues}
                options={argumentsItems}
                onChange={handleChangeArguments}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) =>
                  `${option.stdcode} | ${option.name}`
                }
              />
            </div>
            <div className="error">
              {argumentsError}
              {errors.arguments && errors.arguments.message}
            </div>
            <div className="form-group row">
              <label className="label" style={{ marginTop: "10px" }}>
                计费方式:
              </label>
              <button
                className="btn btn-primary"
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  width: "atuo",
                  display: "inlineblock",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  getPrice();
                }}
                disabled={priceLoading}
              >
                {priceLoading ? "计算中..." : "按选定参数计价"}
              </button>
              <button
                className="btn btn-success"
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  width: "atuo",
                  display: "inlineblock",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  getOnePrice();
                }}
                disabled={priceLoading}
              >
                {priceLoading ? "计算中..." : " 按选定套餐计价"}
              </button>
              <span style={{ color: "red", marginTop: "15px" }}>
                此条以上有改重点计价
              </span>
              <button
                style={{
                  marginTop: "10px",
                  marginLeft: "10px",
                  width: "atuo",
                  display: "inlineblock",
                }}
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  findExtraCost();
                }}
              >
                加载附加费用,会冲掉现填
              </button>
              <span style={{ color: "red" }}>
                {errors.argumentsprice?.message}
              </span>
            </div>
            <div className="form-group row">
              <label className="label">检测依据:</label>
              <Select
                className="select-big"
                name="accordings"
                isMulti
                closeMenuOnSelect={false}
                isClearable
                placeholder="检测依据(可多选)"
                value={accordingsValues}
                options={accordingsItems}
                onChange={handleChangeAccordings}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) =>
                  `${option.stdcode} | ${option.name}`
                }
              />
              <button
                style={{
                  marginLeft: "10px",
                  width: "atuo",
                  height: "36px",
                  display: "inlineblock",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setTurnOnStandards(!turnOnStandards);
                }}
              >
                按项目
              </button>
              <button
                style={{
                  marginLeft: "10px",
                  width: "atuo",
                  height: "36px",
                  display: "inlineblock",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  setTurnOnStandardsPart(true);
                }}
              >
                按参数
              </button>
            </div>
            <div className="error">
              {accordingsError}
              {errors.accordings && errors.accordings.message}
            </div>

            <div className="form-group row">
              <label className="label">判定依据:</label>
              <Select
                className="select-big"
                name="standards"
                isMulti
                closeMenuOnSelect={false}
                isClearable
                placeholder="判定依据(可多选)"
                value={standardsValues}
                options={standardsItems}
                onChange={handleChangeStandards}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) =>
                  `${option.stdcode} | ${option.name}`
                }
              />
            </div>
            <div className="error">
              {standardsError}
              {errors.standards && errors.standards.message}
            </div>
            <hr />

            <ExtraCostList
              initValues={extraCostItemsIn}
              handleExtraCostChange={handleExtraCostChange}
            />
            <div className="form-group row">
              <label className="label">其它费用名:</label>
              <input
                className="half"
                name="othername"
                ref={register}
                type="text"
                defaultValue="杂费"
              />
              <label className="label">其它费用:</label>
              <input
                className="half"
                name="othercost"
                step="0.01"
                ref={register({
                  required: "不能为空",
                  pattern: {
                    value: /[0-9]|\./,
                    message: "error message",
                  },
                  min: {
                    value: 0,
                    message: "不能小于0",
                  },
                })}
                type="number"
                defaultValue="0"
                min="0"
              />
              <label>可以为0,不能为空</label>
            </div>

            <div className="form-group row">
              <label className="label">样品组数:</label>
              <input
                type="number"
                className="half"
                ref={register({
                  required: "不能为空",
                  validate: {
                    greaterZero: (value) =>
                      parseInt(value, 10) > 0 || "不能小于1",
                    lessThanTen: (value) =>
                      parseInt(value, 10) < 10000 || "不能大于1000",
                  },
                })}
                step="1"
                name="num1"
                defaultValue="1"
                min="1"
                max="1000"
              />
              {errors.num1?.message}
              <label className="label">每组数量:</label>
              <input
                type="number"
                step="1"
                className="half"
                ref={register({
                  required: "不能为空",
                  validate: {
                    greaterZero: (value) =>
                      parseInt(value, 10) > 0 || "不能小于1",
                    lessThanTen: (value) =>
                      parseInt(value, 10) < 1000 || "不能大于1000",
                  },
                })}
                name="num2"
                defaultValue="1"
                min="1"
                max="10000"
              />
              {errors.num2?.message}
              <span>组数影响总价,不清楚,请填数字1.</span>
            </div>

            <div className="form-group row">
              <label className="label">总费用:</label>
              <input
                style={{ border: "3px black solid" }}
                className="half"
                name="totalcost"
                type="number"
                step="0.01"
                ref={register({
                  required: "不能为空",
                  pattern: {
                    value: /[0-9]|\./,
                    message: "error message",
                  },
                  min: {
                    value: 0,
                    message: "不能小于0",
                  },
                })}
                defaultValue="0"
                min="0"
              />
              <span style={{ color: "red" }}>{errors.totalcost?.message}</span>
              <p>
                参数金额:
                {isOne
                  ? oneCost
                  : priceItems
                  ? priceItems.map((c) => c.price).reduce((x, y) => x + y, 0)
                  : 0}
                ; 组数:{num1}; (当前填写的工程)--工程折扣:
                {projectNameValues?.discount}
                &nbsp;&nbsp;代码折扣:{codediscount}&nbsp;&nbsp;价格版本:
                {projectPriceVerOld?.name}
                <span style={{ color: "red" }}>
                  {errors.argumentsprice && errors.argumentsprice.message}
                </span>
              </p>
            </div>
            <p>
              复制委托单,请一定要重新点击上边的计价,谨防参数价格发生变化.&nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                (当前工程实时牌价)[当与上边不符时,请先点同步工程价格]&nbsp;&nbsp;&nbsp;&nbsp;工程折扣:
                {projectPriceVerNew?.discount}
                ;&nbsp;&nbsp;价格版本:
                {projectPriceVerNew?.name}
              </span>
            </p>
          </div>

          <div
            className={css`
              grid-column: 1;
              grid-row: 2;
              border: 1px solid black;
              padding: 5px;
            `}
          >
            <div className="form-group row">
              <button
                style={{
                  marginLeft: "10px",
                  width: "atuo",
                  height: "36px",
                  display: "inlineblock",
                }}
                disabled={loading}
                onClick={(e) => {
                  e.preventDefault();
                  fetchMyorder(projectNameValues);
                }}
              >
                {loading ? "正在加载" : "一键填写"}
              </button>
            </div>
            <div
              className="form-group row"
              style={{ paddingLeft: "15%", paddingRight: "5%" }}
            >
              {/* <p>{watch("projectname")}</p> */}
            </div>
            <div className="error">
              {projectNameError}
              {errors.projectname?.message}
            </div>

            <div className="form-group row">
              <label className="label">委托人:</label>
              <input
                className="half"
                name="trustperson"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 50,
                    message: "不能大于50个字",
                  },
                })}
              />
              {errors.trustperson?.message}
              <label className="label">委托人电话:</label>
              <input
                className="half"
                name="trustpersonphone"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 50,
                    message: "不能大于50个字",
                  },
                })}
              />
              {errors.trustpersonphone?.message}
            </div>

            <div className="form-group row">
              <label className="label">取样人:</label>
              <input
                className="half"
                name="sampleperson"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 16,
                    message: "不能大于16个字",
                  },
                })}
              />
              {errors.sampleperson?.message}
              <label className="label">取样人证号:</label>
              <input
                className="half"
                name="sampleperson_cardnumber"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 64,
                    message: "不能大于64个字",
                  },
                })}
              />
              {errors.sampleperson_cardnumber?.message}
            </div>

            <div className="form-group row">
              <label className="label">见证单位:</label>
              <input
                className="line"
                name="lookname"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
              />
              {errors.lookname?.message}
            </div>

            <div className="form-group row">
              <label className="label">见证人:</label>
              <input
                className="half"
                name="lookperson"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 100,
                    message: "不能大于100个字",
                  },
                })}
              />
              {errors.lookperson?.message}
              <label className="label">见证人证号:</label>
              <input
                className="half"
                name="lookperson_cardnumber"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 100,
                    message: "不能大于100个字",
                  },
                })}
              />
              {errors.lookperson_cardnumber?.message}
            </div>

            <div className="form-group row">
              <label className="label">抽样单位:</label>
              <input
                className="line"
                name="samplingname"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
              />
              {errors.samplingname?.message}
            </div>

            <div className="form-group row">
              <label className="label">抽样人:</label>
              <input
                className="half"
                name="samplingperson"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 16,
                    message: "不能大于16个字",
                  },
                })}
              />
              {errors.samplingperson?.message}
              <label className="label">抽样人证号:</label>
              <input
                className="half"
                name="samplingperson_cardnumber"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 64,
                    message: "不能大于64个字",
                  },
                })}
              />
              {errors.samplingperson_cardnumber?.message}
            </div>

            <div className="form-group row">
              <label className="label">支付方式</label>
              <select
                className="half"
                name="payment"
                value={payment}
                onChange={handlePaymentChange}
              >
                <option value="0">现金</option>
                <option value="1">转账</option>
                <option value="2">合同</option>
                <option value="3">年结</option>
              </select>
              <label className="label">样品来源方式:</label>
              <select
                className="half"
                name="samplesource"
                value={sampleSource}
                onChange={handleSampleSourceChange}
              >
                <option value="0">委托送样</option>
                <option value="1">委托取样</option>
                <option value="2">现场检测</option>
              </select>
            </div>

            <div className="form-group row">
              <label className="label">检测类别:</label>
              <select
                className="half"
                name="testkind"
                value={testKind}
                onChange={handleTestKindChange}
              >
                {testkinds.map((item, index) => (
                  <option key={index} value={index}>
                    {item}
                  </option>
                ))}
              </select>
              <label className="label">报告份数:</label>
              <input
                type="number"
                ref={register({
                  required: "不能为空",
                  validate: {
                    greaterZero: (value) =>
                      parseInt(value, 10) > 0 || "不能小于1",
                    lessThanTen: (value) =>
                      parseInt(value, 10) < 21 || "不能大于21",
                  },
                })}
                name="reportqty"
                defaultValue="3"
              />
              {errors.reportqty?.message}
            </div>

            <div className="form-group row">
              <label className="label">样品处置方式:</label>
              <select
                className="half"
                name="sampledealwith"
                value={sampleDealWith}
                onChange={handleSampleDealWithChange}
              >
                <option value="0">检测方处理</option>
                <option value="1">
                  委托方取回（限试验后2个月内，过期检测方自行处理）
                </option>
              </select>

              <label className="label">报告领取方式</label>
              <select
                className="half"
                name="sendway"
                value={sendWay}
                onChange={handleSendWayChange}
              >
                <option value="0">自取</option>
                <option value="1">邮寄</option>
              </select>
            </div>

            <div className="form-group row">
              <label htmlFor="address" className="label">
                邮寄地址:
              </label>
              <input
                className={css`
                  width: 80%;
                  height: 30px;
                  display: inline-block;
                `}
                list="addresses"
                id="address"
                name="address"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="addresses">
                {addressItems &&
                  addressItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
              <span style={{ color: "red" }}>
                {addressError} {errors.address?.message}
              </span>
            </div>
          </div>

          {/* <div className="error"></div> */}

          <div
            className={css`
              grid-column: 1;
              grid-row: 3;
              border: 1px solid black;
              padding-top: 5px;
            `}
          >
            <div className="form-group row">
              <label className="label">工程部位:</label>
              <input
                list="projectparts"
                className="line"
                name="projectpart"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 256,
                    message: "不能大于256个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="projectparts">
                {projectpartItems &&
                  projectpartItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.projectpart?.message}
              {projectpartError}
            </div>

            <div className="form-group row">
              <label className="label">取样地点:</label>
              <input
                list="sampleaddresses"
                className="line"
                name="sampleaddress"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 256,
                    message: "不能大于256个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="sampleaddresses">
                {sampleaddressItems &&
                  sampleaddressItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.sampleaddress?.message}
              {sampleaddressError}
            </div>

            <div className="form-group row">
              <label className="label">样品名称:</label>
              <input
                list="samplenicknames"
                className="line"
                name="samplenickname"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 64,
                    message: "不能大于64个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="samplenicknames">
                {samplenicknameItems &&
                  samplenicknameItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.samplenickname?.message}
              {samplenicknameError}
            </div>

            <div className="form-group row">
              <label className="label">样品状态描述:</label>
              <input
                list="samplestatuses"
                className="line"
                name="samplestatus"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 256,
                    message: "不能大于256个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="samplestatuses">
                {samplestatusItems &&
                  samplestatusItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.samplestatus?.message}
              {samplestatusError}
            </div>

            <div className="form-group row">
              <label className="label">样品数量:</label>
              <input
                list="numberofsamples"
                className="line"
                name="numberofsample"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="numberofsamples">
                {numberofsampleItems &&
                  numberofsampleItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.numberofsample?.message}
              {numberofsampleError}
            </div>

            <div className="form-group row">
              <label className="label">规格/型号:</label>
              <input
                list="modelnumbers"
                className="line"
                name="modelnumber"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 256,
                    message: "不能大于256个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="modelnumbers">
                {modelnumberItems &&
                  modelnumberItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.modelnumber?.message}
              {modelnumberError}
            </div>

            <div className="form-group row">
              <label className="label">生产厂家:</label>
              <Combobox
                className="combox-big"
                onSelect={(item) => {
                  setValue("makefactory", item);
                  setMakeFactoryIsFetch(false);
                  setMakefactory(item);
                }}
              >
                <ComboboxInput
                  value={makeFactory}
                  onChange={handleMakefactoryChange}
                  aria-label="makefactory"
                  autoComplete="off"
                />
                {makefactories.length > 0 && (
                  <ComboboxPopover className="shadow-popup">
                    <ComboboxList>
                      {makefactories.map((item, index) => {
                        return <ComboboxOption key={index} value={item} />;
                      })}
                    </ComboboxList>
                  </ComboboxPopover>
                )}
              </Combobox>
            </div>
            <div className="error">{errors.makefactory?.message}</div>
          </div>

          <div
            className={css`
              grid-column: 1;
              grid-row: 4;
              border: 1px solid black;
              padding-top: 5px;
            `}
          >
            <div className="form-group row">
              <label className="label">设计要求:</label>
              <input
                className="line"
                list="designrequires"
                name="designrequire"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 128,
                    message: "不能大于128个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="designrequires">
                {designrequireItems &&
                  designrequireItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {designrequireError}
              {errors.designrequire?.message}
            </div>

            <div className="form-group row">
              <label className="label">委托方资料:</label>
              <input
                list="trustdocs"
                className="line"
                name="trustdoc"
                type="text"
                ref={register({
                  required: "不能为空",
                  maxLength: {
                    value: 64,
                    message: "不能大于64个字",
                  },
                })}
                autoComplete="off"
              />
              <datalist id="trustdocs">
                {trustdocItems &&
                  trustdocItems.map((item, index) => (
                    <option key={index} value={item} />
                  ))}
              </datalist>
            </div>
            <div className="error">
              {errors.trustdoc?.message}
              {trustdocError}
            </div>

            <div className="form-group row">
              <label className="label">备注:</label>
              <textarea
                name="remark"
                maxLength="256"
                rows="6"
                ref={register({
                  maxLength: {
                    value: 256,
                    message: "不能大于256个字",
                  },
                })}
              />
            </div>
          </div>

          <div
            className={css`
              grid-column: 1;
              grid-row: 5;
              border: 1px solid black;
              padding-top: 5px;
            `}
          >
            <div className="form-group row">
              <label className="label">模板名称:</label>
              <Select
                className="select-small"
                name="modelname"
                placeholder="附加信息模板,可不选"
                value={modelNameValues}
                options={modelNameItems}
                onChange={handleChangeModel}
                // react-select/issues/3018
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => `${option.id} | ${option.name}`}
              />
              <span
                onClick={() => {
                  setModelNameValues(null);
                  setExtraInfoOut(null);
                  setValue("extraInfo", null);
                  setExtraInfoIn([]);
                }}
              >
                我不想要此模板及下边的附加信息了.
              </span>
              <button
                style={{ display: "inlneblock" }}
                onClick={(e) => {
                  e.preventDefault();
                  findExtraInfo();
                }}
              >
                同步附加信息
              </button>
              <div style={{ display: "inlneblock", color: "red" }}>
                {modelNameError}
              </div>
            </div>
            <ExtraInfoList
              initValues={extraInfoIn}
              handleExtraInfoChange={handleExtraInfoChange}
            />
          </div>

          <div
            className={css`
              grid-column: 1;
              grid-row: 6;
              border: 1px solid black;
              padding: 5px;
            `}
          >
            <div className="form-group row">
              <label className="label">报告日期:</label>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                selected={reportDate}
                onChange={handleReportDateChange}
                minDate={subMonths(new Date(), 24)}
                maxDate={addMonths(new Date(), 24)}
                locale="zhCN"
              />
              <label className="label">委托日期:</label>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                selected={trustDate}
                onChange={handleTrustDateChange}
                minDate={subMonths(new Date(), 12)}
                maxDate={addDays(new Date(), 180)}
                locale="zhCN"
              />
            </div>

            <div className="form-group row">
              <label className="label">委托人日期:</label>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                selected={trustPeronDate}
                onChange={handleTrustPersonDateChange}
                minDate={subMonths(new Date(), 12)}
                maxDate={addDays(new Date(), 180)}
                locale="zhCN"
              />

              <label className="label">收样人日期:</label>
              <DatePicker
                dateFormat="yyyy年MM月dd日"
                selected={receivePersonDate}
                onChange={handleReceivePersonDateChange}
                minDate={subMonths(new Date(), 12)}
                maxDate={addDays(new Date(), 180)}
                locale="zhCN"
              />
              <button
                className="btn btn-light"
                onClick={(e) => {
                  e.preventDefault();
                  setReportDate(new Date());
                  setTrustDate(new Date());
                  setTtrustPersonDate(new Date());
                  setReceivePersonDate(new Date());
                  setMiddleReportDate(new Date());
                  setValue("reportdate", format(new Date(), "yyyy-MM-dd"));
                  setValue("trustdate", format(new Date(), "yyyy-MM-dd"));
                  setValue("trustpersondate", format(new Date(), "yyyy-MM-dd"));
                  setValue(
                    "receivepersondate",
                    format(new Date(), "yyyy-MM-dd")
                  );
                  setValue(
                    "middlereportdate",
                    format(new Date(), "yyyy-MM-dd")
                  );
                }}
              >
                重置日期为今天
              </button>
            </div>
            <div className="form-group row">
              <label
                htmlFor="ismiddlereport"
                className="col-sm-2 col-form-label"
              >
                是否需要中间报告
              </label>
              <div className="col-sm-1">
                <input
                  className="form-control"
                  name="ismiddlereport"
                  type="checkbox"
                  ref={register}
                />
              </div>
              <label>中间报告日期:</label>
              <div className="col-sm-5">
                <DatePicker
                  disabled={!watch("ismiddlereport")}
                  dateFormat="yyyy年MM月dd日"
                  selected={middleReportDate}
                  onChange={handleMiddleReportDateChange}
                  minDate={subMonths(new Date(), 3)}
                  maxDate={addMonths(new Date(), 12)}
                  locale="zhCN"
                />
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-2">
                <button
                  className="btn btn-primary"
                  disabled={isSubmit}
                  onClick={() => {
                    setIsDownload(false);
                    handleSubmit();
                  }}
                >
                  {isSubmit
                    ? "正在提交..."
                    : operateInside === "edite"
                    ? "仅修改"
                    : "仅新增"}
                </button>
              </div>

              <div className="col-sm-2">
                <button
                  className="btn btn-primary"
                  disabled={isSubmit}
                  onClick={(e) => {
                    handleSubmit();
                    setIsDownload(true);
                  }}
                >
                  {isSubmit
                    ? "正在提交..."
                    : operateInside === "edite"
                    ? "修改并下载"
                    : "新增并下载"}
                </button>
              </div>

              <div className="col-sm-1">
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    //reset();
                    clear(e);
                    //setValue("tag",3);
                    //inputTrustName.current.value = "";
                  }}
                >
                  清空
                </button>
              </div>
              <Link to="/samplelist2">返回主查询</Link>
              <div className="col-sm-3">
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    copyLastSample();
                    setValue("tag", 3);
                  }}
                >
                  复制最后一单(覆盖所填)
                </button>
              </div>

              <div className="col-sm-3">
                <button
                  className="btn btn-danger"
                  disabled={operateInside !== "edite"}
                  onClick={(e) => {
                    e.preventDefault();
                    setOperateInside("add");
                    setValue("tag", 4);
                  }}
                >
                  修改转新增(慎用,以防重开单)
                </button>
              </div>
            </div>
            <span>{msg}</span>
            <span style={{ color: "red" }}>
              {errors.argumentsprice?.message}
            </span>
          </div>
          <div
            className={css`
              grid-column: 1;
              grid-row: 7;
              border: 1px solid black;
              padding: 5px;
            `}
          >
            <p>归属项目:{categoryValues?.name}</p>
            <p>
              归属样品名称(固定称号):{sampleCodeValues?.stdcode}
              {sampleCodeValues?.name}
            </p>
            <p>委托单号:{trustNo()}</p>
            <p>样品名称:{watch("samplenickname")} </p>
            <p>样品单号:{sampleNo()}</p>

            <p>检测参数:</p>
            <ol>
              {argumentsValues &&
                argumentsValues.map((item) => (
                  <li key={item.id}>
                    {item.stdcode}--{item.name}--价格:(
                    {priceItems &&
                      priceItems.find((c) => c.argument_id === item.id) &&
                      priceItems.find((c) => c.argument_id === item.id)?.price}
                    元)
                  </li>
                ))}
            </ol>
            <p>附加附费用:</p>
            <ol>
              {extraCostItemsOut &&
                extraCostItemsOut.map((item) => (
                  <li key={item.id}>
                    {item.name}--{item.price}元--{item.qty}
                    {item.unit}--{item.remark}
                  </li>
                ))}
            </ol>
            <p>
              {watch("othername")}:{watch("othercost")}{" "}
            </p>
            <p>
              参数金额:
              {isOne
                ? oneCost
                : priceItems
                ? priceItems.map((c) => c.price).reduce((x, y) => x + y, 0)
                : 0}
            </p>
            <p>工程折扣: {projectNameValues && projectNameValues.discount}</p>
            <p>代码折扣:{codediscount}</p>

            <p>总费用:{watch("totalcost")} </p>
            <p>检测依据:</p>
            <ol>
              {accordingsValues &&
                accordingsValues.map((item) => (
                  <li key={item.id}>
                    {item.stdcode}--{item.name}
                  </li>
                ))}
            </ol>

            <p>判定依据:</p>
            <ol>
              {standardsValues &&
                standardsValues.map((item) => (
                  <li key={item.id}>
                    {item.stdcode}--{item.name}
                  </li>
                ))}
            </ol>
            <hr />
            <p>委托单位:{customerValues?.name} </p>
            {/* <p>工程名称:{watch("projectname")} </p> */}
            <p>委托人:{watch("trustperson")} </p>
            <p>委托人电话:{watch("trustperphone")} </p>
            <p>取样人:{watch("sampleperson")} </p>
            <p>取样人证号:{watch("sampleperson_cardnumber")} </p>
            <p>见证单位:{watch("lookname")} </p>
            <p>见证人:{watch("lookperson")} </p>
            <p>见证证号:{watch("lookperson_cardnumber")} </p>
            <p>抽样单位:{watch("samplingname")} </p>
            <p>抽样人:{watch("samplingperson")} </p>
            <p>抽样人证号:{watch("samplingperson_cardnumber")} </p>
            <hr />

            <p>工程部位:{watch("projectpart")} </p>
            <p>取样地址:{watch("sampleaddress")} </p>
            <p>样品名称:{watch("samplenickname")} </p>
            <p>样品描述:{watch("samplestatus")} </p>

            <p>样品数量:{watch("numberofsample")} </p>
            <p>规格/型号/牌号:{watch("modelnumber")} </p>
            <p>样品数量:{watch("numberofsample")} </p>
            <p>制造工厂:{watch("makefactory")} </p>
            <p>设计要求:{watch("designrequire")} </p>
            <p>委托文档:{watch("trustdoc")} </p>
            <p>备注:{watch("remark")} </p>
            <hr />

            <p>支付方式:{payments[parseInt(payment)]}</p>
            <p>
              样品来源:
              {samplesources[parseInt(sampleSource)]}
            </p>
            <p>测试种类:{testkinds[parseInt(testKind)]} </p>
            <hr />
            <p>
              报告份数:
              {watch("reportqty")}份
            </p>

            <p>
              样品处置:
              {sampledealwiths[parseInt(sampleDealWith)]}
            </p>

            <p>报告领取方式:{sendways[parseInt(sendWay)]}</p>
            <p>寄送地址:{watch("address")} </p>

            <p>报告日期:{format(reportDate, "yyyy-MM-dd", new Date())} </p>
            <p>委托日确:{format(trustDate, "yyyy-MM-dd", new Date())} </p>
            <p>
              委托人签名日期:
              {format(trustPeronDate, "yyyy-MM-dd", new Date())}{" "}
            </p>
            <p>
              收样日期:{format(receivePersonDate, "yyyy-MM-dd", new Date())}{" "}
            </p>
            <p>附加信息:</p>
            <ol>
              {extraInfoOut &&
                extraInfoOut.map((item) => (
                  <li key={item.id}>
                    {item.print ? "打印" : "不打印"}|{item.name}:{item.value}
                  </li>
                ))}
            </ol>
            <p>{watch("ismiddlereport") ? "需要中间报告" : "不需要中间报告"}</p>
            <p>
              中间报告日期:
              {format(middleReportDate, "yyyy-MM-dd", new Date())}
            </p>
            <button
              className="btn btn-primary"
              disabled={isSubmit}
              onClick={() => {
                setIsDownload(false);
                handleSubmit();
              }}
            >
              {isSubmit
                ? "正在提交..."
                : operateInside === "edite"
                ? "仅修改"
                : "仅新增"}
            </button>

            <button
              style={{ marginLeft: "20px" }}
              className="btn btn-primary"
              disabled={isSubmit}
              onClick={() => {
                setIsDownload(true);
                handleSubmit();
              }}
            >
              {isSubmit
                ? "正在提交..."
                : operateInside === "edite"
                ? "修改并下载"
                : "新增并下载"}
            </button>
            <p>{msg}</p>
          </div>

          <div
            className={css`
              grid-column: 2;
              grid-row: 1 / 2;
              border: 1px solid black;
              box-sizing: border-box;
              padding: 10px;
            `}
          >
            <div>
              <p
                className={css`
                  padding: 0px;
                  margin: 0;
                `}
              >
                委托单号
              </p>
              <p
                className={css`
                  padding: 0px;
                  margin: 0;
                `}
              >
                {trustNo()}
              </p>
              <p
                className={css`
                  padding: 0px;
                  margin: 0;
                `}
              >
                样品编号
              </p>
              <p
                className={css`
                  padding: 0px;
                  margin: 0;
                `}
              >
                {sampleNo()}
              </p>
            </div>
            <div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      transmitProjectPrice();
                    }}
                  >
                    同步工程价格
                  </button>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      findPkg();
                    }}
                  >
                    同步参数包
                  </button>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      findArguments();
                    }}
                  >
                    同步参数
                  </button>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      findStandard();
                    }}
                  >
                    同步检测和判定依据
                  </button>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-12">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      if (argumentsValues && argumentsValues.length > 0) {
                        openArgumentsProperty();
                      }
                    }}
                  >
                    为所选参数补充属性
                  </button>
                </div>
              </div>
              <div>
                <p>检测参数价格清单:</p>
                <ol>
                  {argumentsValues &&
                    argumentsValues.map((item) => (
                      <li key={item.id}>
                        {item.stdcode}
                        {item.name}(
                        {priceItems &&
                          priceItems.find((c) => c.argument_id === item.id) &&
                          priceItems.find((c) => c.argument_id === item.id)
                            ?.price}
                        元)
                      </li>
                    ))}
                </ol>
                <p>
                  参数金额:
                  {isOne
                    ? oneCost
                    : priceItems
                    ? priceItems.map((c) => c.price).reduce((x, y) => x + y, 0)
                    : 0}
                  {isOne ? "套餐价" : null}
                </p>
                <p>价格版本:{projectPriceVerOld?.name}</p>
                <p>工程折扣:{projectNameValues?.discount}</p>
                <p>代码折扣:{codediscount} </p>
              </div>
            </div>
          </div>

          <div
            className={css`
              grid-column: 2;
              grid-row: 2 / 6;
              border: 1px solid black;
            `}
          >
            {count}
            {msg}
            <hr />
            <p>参数属性</p>
            <ol>
              {argumentsValues &&
                argumentPropertyOut &&
                argumentsValues.map((item) => (
                  <li key={item.id}>
                    {item.stdcode}
                    {item.name}(
                    {argumentPropertyOut &&
                      argumentPropertyOut.find((c) => c.id === item.id) &&
                      argumentPropertyOut.find((c) => c.id === item.id)
                        ?.property}
                    )
                  </li>
                ))}
            </ol>

            <button
              className="btn btn-primary"
              disabled={isSubmit}
              onClick={() => {
                setIsDownload(false);
                handleSubmit();
              }}
            >
              {isSubmit
                ? "正在提交..."
                : operateInside === "edite"
                ? "仅修改"
                : "仅新增"}
            </button>
          </div>
        </div>
      </form>
      {showDialogArgumentsProperty && (
        <Dialog
          isOpen={showDialogArgumentsProperty}
          onDismiss={closeArgumentsProperty}
          aria-label={"test"}
        >
          <ArgumentProperty
            initValues={argumentPropertyIn}
            transmitArgumentItems={argumentsValues}
            handleArgumentPropertyChange={handleArgumentPropertyChange}
          />
          <button
            ref={cancelRef}
            onClick={() => {
              setArgumentPropertyIn(argumentPropertyOut);
              setValue("argumentsproperty", argumentPropertyOut);
              closeArgumentsProperty();
            }}
          >
            保存并关闭
          </button>
        </Dialog>
      )}
    </>
  );
}
